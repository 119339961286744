.wrapper {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #008080;
}
.window {
  width: clamp(400px, 70%, 500px);
}

.elementToFadeInAndOut {
  opacity: 1;
  transition: opacity 200ms linear;
}
.address-field {
  width: 95%;
  /* margin: .5em */
}